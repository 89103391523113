<template>
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-n2">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-muted">Aktivitas Auditor</span>
      </h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body pt-5" v-if="data">
      <!--begin::Timeline-->
      <div class="timeline-label">
        <!--begin::Item-->
        <div class="timeline-item" v-for="(item, i) in data" :key="i">
          <!--begin::Badge-->
          <div class="timeline-badge">
            <span class="svg-icon svg-icon-1 mt-n5 ms-n5">
              <inline-svg :src="item.type_activity.icon" />
            </span>
            <!-- <i class="fa fa-genderless text-warning fs-1"></i> -->
          </div>
          <!--end::Badge-->

          <!--begin::Text-->
          <div class="fw-mormal timeline-content text-muted ps-4 mb-5">
            <a :href="'/person-map/detail/'+item.data.person.id">
            <span class="fw-bold text-default">
              {{ item.data.person.nik + " " }}
            </span>
            <span> absen kehadiran di </span>
            <span class="fw-bold text-default">
              {{ item.data.person.name + " " }}
            </span>
            <div class="text-muted">
              {{ item.activity.time }}
            </div>
            </a>
          </div>
          <!--end::Text-->
        </div>
        <!--end::Item-->
      </div>
      <!--end::Timeline-->
    </div>
    <!--end: Card Body-->
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
  props: {
    widgetClasses: String,
    widgetData: Object,
  },
  setup(props) {
    const data = ref([]);

    onMounted(() => {
      setTimeout(() => {
        for (let index = 0; index < 1; ) {
          if (props.widgetData) {
            data.value = props.widgetData;
            index++;
          }
        }
      }, 100);
    });

    return {
      data,
    };
  },
};
</script>
